import { useState } from "react";
import emailjs from "emailjs-com";
import { Link as RouterLink } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleScrollTo = (element) => {
    scroller.scrollTo(element, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      from_email: email,
      to_email: "contact@bytebeamtech.com",
    };

    emailjs
      .send(
        "service_y5kpnua",
        "template_2loxv67",
        templateParams,
        "U5ZSLR7MdyI5gXpcf"
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
        setEmail("");
      })
      .catch((error) => {
        console.error("Email failed to send:", error);
      });
  };

  return (
    <section className="section bg-light bg-footer pb-5" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="footer-info mt-4">
              <span className="logo-dark">Byte Beam Technologies</span>
              <p className="text-muted mt-3 mb-1">
                Embark on a journey of IT excellence.
              </p>
              <p className="text-muted mb-2">
                Contact us on{" "}
                <a
                  href="mailto:contact@bytebeamtech.com"
                  className="custom-contact"
                >
                  <strong>contact@bytebeamtech.com</strong>
                </a>{" "}
                <br />
                Call us at{" "}
                <strong className="custom-contact">+91 7510008881</strong>
              </p>

              <div className="team-social mt-4 pt-2">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/bytebeam-technologies/"
                      className="text-reset"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-linkedin-box"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://twitter.com/ByteBeamTech"
                      className="text-reset"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/bytebeamtech/"
                      className="text-reset"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://in.pinterest.com/bytebeamtechnologies/"
                      className="text-reset"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="row pl-0 md-lg-5">
              <div className="col-lg-6">
                <div className="mt-4">
                  <h5 className="f-20">Services</h5>
                  <ul className="list-unstyled footer-link mt-3">
                    <li>
                      <RouterLink
                        to="/"
                        onClick={() => handleScrollTo("services")}
                      >
                        Web Development
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink
                        to="/"
                        onClick={() => handleScrollTo("services")}
                      >
                        App Development
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink
                        to="/"
                        onClick={() => handleScrollTo("services")}
                      >
                        Custom Dashboard
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink
                        to="/"
                        onClick={() => handleScrollTo("services")}
                      >
                        Cyber Security
                      </RouterLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mt-4">
                  <h5 className="f-20">Company</h5>
                  <ul className="list-unstyled footer-link mt-3">
                    <li>
                      <RouterLink
                        to="/"
                        onClick={() => handleScrollTo("services")}
                      >
                        Services
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink
                        to="/"
                        onClick={() => handleScrollTo("clients")}
                      >
                        Clients Review
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink
                        to="/"
                        onClick={() => handleScrollTo("contact")}
                      >
                        Contact us
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/privacy-policy">
                        Privacy Policy
                      </RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="mt-4">
              <h5 className="f-20">Subscribe</h5>

              <div className="subscribe mt-2 pt-1">
                <form onSubmit={handleSubmit}>
                  <input
                    placeholder="Enter Email"
                    type="email"
                    value={email}
                    onChange={handleInputChange}
                    required
                  />
                  <button type="submit" className="btn btn-primary">
                    <i className="mdi mdi-send"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-5" />

        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <p className="text-muted mb-0">2024 © Byte Beam Technologies.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
