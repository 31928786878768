import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Main from "./page/main";
import PrivacyPolicy from "./components/privacyPolicy";
import LearnMore from "./page/learnMore";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/:page" element={<LearnMore />} />
      </Routes>
      <Footer />
      <FloatingWhatsApp
        phoneNumber="+917510008881"
        statusMessage="typically replies within a minutes"
        accountName="BYTE BEAM TECHNOLOGIES"
        avatar="images/bb-logo.jpg"
      />
    </Router>
  );
}

export default App;
