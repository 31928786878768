const Brands = () => {
  return (
    <section className="section bg-light bg-clients">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box text-center">
              <h3 className="title-heading mt-4">All Trusted Brands</h3>
              <p className="text-muted f-17 mt-3">
                A curated collection of industry-leading products and services,
                meticulously selected for their <br /> quality, reliability, and
                innovation. Explore excellence with us.
              </p>

              <img
                src="images/home-border.png"
                height="15"
                className="mt-3"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-4">
          <div className="col-lg-3">
            <div className="client-images mt-3">
              <img
                src="images/clients/webdesys.png"
                alt="logo-img"
                className="mx-auto img-fluid d-block"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="client-images mt-4">
              <img
                src="images/clients/tapvox.png"
                alt="logo-img"
                className="mx-auto img-fluid d-block"
              />
            </div>
          </div>
          <div className="col-lg-3 ">
            <div className="client-images mt-4">
              <img
                src="images/clients/gurukul.png"
                alt="logo-img"
                className="mx-auto img-fluid d-block"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="client-images mt-4">
              <img
                src="images/clients/kidzee.png"
                alt="logo-img"
                className="mx-auto img-fluid d-block"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
