const Features = () => {
  return (
    <section className="section bg-light bg-features">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="mt-4 home-img">
              <div className="animation-2"></div>
              <div className="animation-3"></div>
              <img
                src="images/features/img-1.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-6 offset-lg-1">
            <div className="mt-4">
              <h2>Team Collaboration in real time</h2>
              <p className="mt-4 text-muted">
                {`Welcome to Byte Beam Technologies, we illuminate how our dynamic team synchronizes efforts instantly to 
                fuel innovation and excellence in mobile app development. Dive into our real-time collaboration methods, 
                where every pixel and line of code is infused with creativity and precision. Join us at Byte Beam Technologies as
                 we redefine the art of teamwork in the digital age.`}
              </p>

              <div className="mt-4">
                <a href="" className="btn btn-primary">
                  Explore More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
