const GetStarted = () => {
  return (
    <section className="section bg-light bg-cta">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center">
              <h2>
                Get Started With{" "}
                <span className="text-primary">Byte Beam Technologies</span>
              </h2>
              <p className="text-muted mt-3">
                Welcome to Byte Beam Technologies , where digital innovation
                meets unwavering excellence. At the helm is a dynamic leadership
                team, spearheaded by our visionary CEO with a decade of hands-on
                experience. Complemented by Chief Technology Officers (CTOs)
                from renowned institutions such as NIIT and BITS Pilani, and
                guided by a seasoned industry expert with over 16 years of
                mastery, Byte Beam Technologies emerges as a trailblazer in the
                realm of IT solutions.
              </p>

              <div className="mt-4 pt-2">
                <a
                  href="#footer"
                  className="btn btn-soft-primary btn-round mr-3 btn-rounded"
                >
                  Request a demo
                </a>
                <a href="#contact" className="btn btn-primary btn-round btn-rounded">
                  Get Started Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
