export const ServiceData = [
  {
    id: 1,
    title: "Web Development",
    description:
      "Creating captivating digital experiences for your brand with sleek designs and seamless functionality to drive growth and engagement.",
    details:
      "In today's fast-paced digital landscape, web development is essential for businesses seeking efficiency, scalability, and innovation. Byte Beam Technologies excels in providing top-notch web development services, ensuring that your business stands out in the competitive market.",
    url: "/web-development",
    icon: "monitor-multiple",
  },
  {
    id: 2,
    title: "App Development",
    description:
      "Join our vibrant mobile development team, where creativity, talent, and precision thrive. Let's collaborate to craft innovative mobile apps at the forefront of technology!",
    details:
      "In today’s digital era, mobile applications have become indispensable tools for businesses, offering new ways to engage with customers, streamline operations, and drive growth. Byte Beam Technologies excels in providing top-tier app development services, ensuring that your business stands out in the competitive app market.",
    url: "/app-development",
    icon: "cellphone-android",
  },
  {
    id: 3,
    title: "Cloud Computing",
    description:
      "Secure your digital assets with cloud computing. Scale effortlessly, fortify defenses, and ensure compliance for robust protection.",
    details:
      "In today's fast-paced digital landscape, cloud computing has become an essential technology for businesses seeking efficiency, scalability, and innovation. Byte Beam Technologies stands at the forefront of cloud computing, offering comprehensive and customized solutions to meet the diverse needs of our clients.",
    url: "/cloud-computing",
    icon: "cloud-tags",
  },
  {
    id: 4,
    title: "Cyber Security Solutions",
    description:
      "Byte Beam Technologies, we understand that cybersecurity is not a one-size-fits-all approach. We offer tailored cybersecurity solutions designed to address specific threats and vulnerabilities faced by your business.",
    details:
      "At Byte Beam Technologies, we understand that cybersecurity is not a one-size-fits-all approach. We offer tailored cybersecurity solutions designed to address specific threats and vulnerabilities faced by your business",
    url: "/cyber-security",
    icon: "check-network",
  },
  {
    id: 5,
    title: "Automation Solutions",
    description:
      "Revolutionizing your business with cutting-edge automation solutions that streamline workflows, enhance efficiency, and drive productivity for a smarter, more agile enterprise.",
    details:
      "Our automation solutions help businesses streamline workflows, enhance efficiency, and drive productivity. Byte Beam Technologies uses cutting-edge technology to deliver innovative solutions tailored to your business needs.",
    url: "/automation-solutions",
    icon: "alpha-a-circle-outline",
  },
  {
    id: 6,
    title: "Custom ERP Solutions",
    description:
      "Empowering your business with tailored ERP solutions designed to integrate processes, optimize operations, and boost productivity for unparalleled growth and efficiency.",
    details:
      "Our custom ERP solutions are designed to integrate processes, optimize operations, and boost productivity. Byte Beam Technologies creates tailored solutions to meet the specific requirements of your business.",
    url: "/custom-erp-solutions",
    icon: "ballot-recount",
  },
];
