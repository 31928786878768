import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LearnContent } from "./learnMoreData";
import "./learnMore.css";

const LearnMore = () => {
  const { page } = useParams();
  const contentData = LearnContent.find(
    (content) => content.url === `/${page}`
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!contentData) {
    return (
      <div className="container py-5 learn-more">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="text-center mb-4">Page Not Found</h1>
            <p className="text-center">
              The content you are looking for does not exist.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container py-5 learn-more">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <h1 className="text-center mb-4">{contentData.title}</h1>
          <h2 className="text-center mb-4">{contentData.subtitle}</h2>
          <div dangerouslySetInnerHTML={{ __html: contentData.content }} />
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
