const Client = () => {
  return (
    <section className="section" id="clients">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box text-center">
              <h3 className="title-heading mt-4">Clients Loved Products</h3>
              <p className="text-muted f-17 mt-3">
                {`We're passionate about solving your tech challenges. Whether it's building custom software, 
                securing your data, or helping you transition to the cloud or more we're your trusted IT ally. 
                Let's work together to turn your digital dreams into reality. `}
              </p>

              <img
                src="images/home-border.png"
                height="15"
                className="mt-3"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-8 col-center m-auto">
              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="item carousel-item active">
                    <div className="img-box">
                      <img
                        src="images/testi-img/tapvoxlogo.png"
                        className="object-fit-contain"
                        alt=""
                      />
                    </div>
                    <p className="testimonial">
                      Working with Byte Beam Technologies has been an absolute
                      pleasure. Their team not only delivered a cutting-edge
                      solution but also exceeded our expectations in terms of
                      communication, timeliness, and overall professionalism.
                      Highly recommend!
                    </p>
                    <p className="overview">
                      <b>Tapvox</b>, CEO
                    </p>
                  </div>
                  <div className="item carousel-item">
                    <div className="img-box">
                      <img
                        src="images/testi-img/webdesy.png"
                        className="object-fit-contain"
                        alt=""
                      />
                    </div>
                    <p className="testimonial">
                      Choosing Byte Beam Technologies was one of the best
                      decisions we made for our project. Their attention to
                      detail, proactive communication, and commitment to quality
                      resulted in a successful outcome that surpassed our goals.
                      Looking forward to collaborating with them again!
                    </p>
                    <p className="overview">
                      <b>Webdesys</b>, CEO
                    </p>
                  </div>
                  <div className="item carousel-item">
                    <div className="img-box">
                      <img
                        src="images/testi-img/kidzeelogo.gif"
                        className="object-fit-contain"
                        alt=""
                      />
                    </div>
                    <p className="testimonial">
                      Choosing Byte Beam Technologies was a game-changer for us.
                      Their generosity of time, expertise, and attention to
                      detail made our project not just successful, but a
                      delightful experience. Grateful for their partnership!
                      Kidzee Play School
                    </p>
                    <p className="overview">
                      <b>Kidzee</b>, Manager
                    </p>
                  </div>
                  <div className="item carousel-item">
                    <div className="img-box">
                      <img
                        src="images/testi-img/gurukul.png"
                        className="object-fit-contain"
                        alt=""
                      />
                    </div>
                    <p className="testimonial">
                      Working with Byte Beam Technologies was a breath of fresh
                      air. Their team creativity, technical prowess, and
                      collaborative approach made our project a success. Highly
                      impressed and looking forward to future collaborations!
                      Gurukul Children Academy, India
                    </p>
                    <p className="overview">
                      <b>Gurukul Children Academy</b>, Manager
                    </p>
                  </div>
                </div>

                <a
                  className="carousel-control left carousel-control-prev"
                  href="#myCarousel"
                  data-slide="prev"
                >
                  <i className="fa fa-angle-left"></i>
                </a>
                <a
                  className="carousel-control right carousel-control-next"
                  href="#myCarousel"
                  data-slide="next"
                >
                  <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Client;
