import { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const templateParams = {
      from_name: formData.firstName + " " + formData.lastName,
      from_email: formData.email,
      to_email: "contact@bytebeamtech.com",
      message: formData.message,
    };

    emailjs
      .send(
        "service_y5kpnua",
        "template_2loxv67",
        templateParams,
        "U5ZSLR7MdyI5gXpcf"
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
        setIsSubmitting(false); 
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Email failed to send:", error);
        setIsSubmitting(false); 
      });
  };

  return (
    <section className="section" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box text-center">
              <h3 className="title-heading mt-4">{`Let's talk about everything!`}</h3>
              <p className="text-muted f-17 mt-3">
                Seize the bytes! Fill out the form and let Byte Beam
                Technologies craft your digital <br/> masterpiece with precision and
                pizzazz!
              </p>

              <img
                src="images/home-border.png"
                height="15"
                className="mt-3"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-4">
          <div className="col-lg-6">
            <div className="mt-4 home-img text-center">
              <div className="animation-2"></div>
              <div className="animation-3"></div>
              <img
                src="images/features/img-3.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="custom-form mt-4">
              <div id="message"></div>
              <form
                method="post"
                name="contact-form"
                id="contact-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-3">
                      <label className="contact-label">First Name</label>
                      <input
                        name="firstName"
                        id="firstName"
                        className="form-control"
                        type="text"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mt-3">
                      <label className="contact-label">Last Name</label>
                      <input
                        name="lastName"
                        id="lastName"
                        className="form-control"
                        type="text"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-3">
                      <label className="contact-label">Email Address</label>
                      <input
                        name="email"
                        id="email"
                        className="form-control"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-3">
                      <label className="contact-label">Subject</label>
                      <input
                        name="subject"
                        id="subject"
                        className="form-control"
                        type="text"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-3">
                      <label className="contact-label">Your Message</label>
                      <textarea
                        name="message"
                        id="message"
                        rows="5"
                        className="form-control"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 mt-3 text-right">
                    <input
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-primary btn-round"
                      value="Send Message"
                      type="submit"
                      disabled={isSubmitting} 
                      required
                    />
                    <div id="simple-msg"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
