import Brands from "../components/brands";
import Client from "../components/client";
import Contact from "../components/contact";
import Features from "../components/features";

import GetStarted from "../components/getStarted";

import Home from "../components/home";
import Services from "../components/services";

const Main = () => {
  return (
    <>
      <Home />
      <Services />
      <Features />
      <GetStarted />
      <Client />
      <Brands />
      <Contact />
    </>
  );
};

export default Main;
