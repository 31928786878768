import { Link } from "react-router-dom";
import { ServiceData } from "./ServiceData.js";
const Services = () => {
  return (
    <section className="section bg-services" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box text-center">
              <h3 className="title-heading mt-4">Provide Awesome Services </h3>
              <p className="text-muted f-17 mt-3">
                Unlock the power of the web with our top-notch services! As a
                leading web development company <br /> we craft digital
                solutions tailored to your needs, ensuring excellence at every
                step.
              </p>

              <img
                src="images/home-border.png"
                height="15"
                className="mt-3"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-4">
          {ServiceData.map((service) => (
            <div className="col-lg-4" key={service.id}>
              <div className="services-box p-4 mt-4">
                <div className="services-icon bg-soft-primary">
                  <i className={`mdi mdi-${service.icon} text-primary`}></i>
                </div>

                <h5 className="mt-4">{service.title}</h5>
                <p className="text-muted mt-3">{service.description}</p>

                <div className="mt-3">
                  <Link to={service.url} className="text-primary f-16">
                    Learn More <i className="mdi mdi-arrow-right ml-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
