import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";
import "./Header.css";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const isHomeRoute = location.pathname === "/";

  const handleScrollTo = (element) => {
    scroller.scrollTo(element, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light ${
        isHomeRoute && !isScrolled ? "home-route" : ""
      } ${isScrolled ? "scrolled" : ""}`}
    >
      <div className="container">
        <RouterLink
          className="navbar-brand logo text-uppercase"
          to="/"
          onClick={() => scroll.scrollToTop()}
        >
          <span className="logo-light">Byte Beam Technologies</span>
          <span className="logo-dark">Byte Beam Technologies</span>
        </RouterLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
            <li className="nav-item">
              <RouterLink
                to="/"
                className="nav-link smoothlink"
                onClick={() => scroll.scrollToTop()}
              >
                Home
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink
                to="/"
                className="nav-link smoothlink"
                onClick={() => handleScrollTo("services")}
              >
                Services
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink
                to="/"
                className="nav-link smoothlink"
                onClick={() => handleScrollTo("clients")}
              >
                Clients
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink
                to="/"
                className="nav-link smoothlink"
                onClick={() => handleScrollTo("contact")}
              >
                Contact
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
