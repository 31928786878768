const Home = () => {
  return (
    <section className="bg-home home-bg-2" id="home">
      <div className="bg-overlay"></div>
      <div className="home-center">
        <div className="home-desc-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="home-content text-center">
                  <p className="mb-0 text-white">Web Designing</p>
                  <img src="images/home-border.png" height="15" alt="" />
                  <h1 className="mt-4 pt-3 home-title text-white">
                    Revolutionize Your Business Presence & Management with{" "}
                    <span
                      className="element text-primary"
                      data-elements="Byte Beam Technologies"
                    >
                      Byte Beam Technologies
                    </span>
                  </h1>

                  <p className="text-white-50 mt-4 f-20">
                    Embark on a journey of IT excellence. Let our expertise be
                    the wind beneath your digital wings, propelling you towards
                    unprecedented success.
                  </p>
                  <div className="mt-4 pt-2">
                    <a href="#contact" className="btn btn-primary mr-3">
                      Contact Us
                    </a>
                    {/* <a
                      href="http://vimeo.com/99025203"
                      className="video-play-icon text-white"
                    >
                      <i className="mdi mdi-play-circle-outline text-white mr-2"></i>
                      Watch Intro Video
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
